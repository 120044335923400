import { useAxios } from '../useAxios';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createProject,
  createSavedTab,
  deleteSavedTab,
  deleteProject,
  exportCsvFromProject,
  getProject,
  getProjectEmails,
  getProjects,
  importCsvToProject,
  setDefaultCriteria,
  updateProject,
  attachCvToCandidate
} from '../../services/api/projects';
import config from '../../config';
import { CsvExportOptionsEnum, IImportCsv, IProject } from '@recone/models';
import { useTeamId } from './auth';
import { useGraphQlRequest } from '../useGraphQLClient';
import { LinkedinUsersSearchInputType, useGetFilterProjectQuery } from '../../gql/generated/graphql';
import { ICriteria } from '../../../../../backend/models/src/repository/criteria.repository';
import { GET_CRITERIAS_QUERY_ID } from './criteria';

const GET_PROJECTS_QUERY_ID = 'projects';
const GET_TARGETS_QUERY_ID = 'targets';
const GET_FILTER_DATA = 'filter';

export function useGetProjects(limit = config.service.QUERY_DEFAULT_LIMIT) {
  const axios = useAxios();
  const teamId = useTeamId();

  return useInfiniteQuery([GET_PROJECTS_QUERY_ID], ({ pageParam }) => getProjects(axios, teamId, pageParam, limit), {
    enabled: !!teamId,
    getNextPageParam: (lastData, pages) => pages.length
  });
}

export function useCreateProject() {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation(
    (args: { projectName: string; description: string; candidatesNumber: number }) =>
      createProject(axios, teamId, args.projectName, args.description, args.candidatesNumber),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_PROJECTS_QUERY_ID]).then();
      }
    }
  );
}

export function useUpdateProject() {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation((args: { project: Partial<IProject> }) => updateProject(axios, teamId, args.project), {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_PROJECTS_QUERY_ID]).then();
    }
  });
}

export function useDeleteProject() {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation((args: { projectId: string }) => deleteProject(axios, teamId, args.projectId), {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_PROJECTS_QUERY_ID]).then();
    }
  });
}

export function useCreateSavedTabs(projectId: string) {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation(
    (args: { projectId: string; name: string }) => createSavedTab(axios, teamId, args.projectId, args.name),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_PROJECTS_QUERY_ID, projectId]).then();
      }
    }
  );
}

export function useDeleteSavedTab(projectId: string) {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation(
    (args: { projectId: string; savedTabId: string }) => deleteSavedTab(axios, teamId, args.projectId, args.savedTabId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_PROJECTS_QUERY_ID, projectId]).then();
      }
    }
  );
}

export function useGetProject(projectId: string) {
  const axios = useAxios();
  const teamId = useTeamId();

  return useQuery([GET_PROJECTS_QUERY_ID, projectId], () => getProject(axios, teamId, projectId), {
    enabled: !!teamId && !!projectId
  });
}

export function useImportCsvToProject() {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation(
    (args: { payload: IImportCsv[]; projectId: string }) =>
      importCsvToProject(axios, teamId, args.payload, args.projectId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_TARGETS_QUERY_ID]).then();
      }
    }
  );
}

export function useExportCsvFromProject() {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation(
    (args: { id: string; exportOptions: CsvExportOptionsEnum }) =>
      exportCsvFromProject(axios, teamId, args.id, args.exportOptions),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_PROJECTS_QUERY_ID]).then();
      }
    }
  );
}

export function useGetEmailsForProject() {
  const axios = useAxios();
  const teamId = useTeamId();

  return useMutation((args: { projectId: string }) => getProjectEmails(axios, teamId, args.projectId));
}

export function useGetProjectFiltered(filter: LinkedinUsersSearchInputType, limit = 25, skip = 0) {
  const graphqlRequest = useGraphQlRequest();
  const teamId = useTeamId();

  return useGetFilterProjectQuery(graphqlRequest, { filter, limit, skip });
}

export function useSetDefaultCriteria(projectId: string) {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation(
    (args: { criteriaId: string | null }) => setDefaultCriteria(axios, teamId, projectId, args.criteriaId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_PROJECTS_QUERY_ID, projectId]).then();
        queryClient.invalidateQueries([GET_TARGETS_QUERY_ID]).then();
        queryClient.invalidateQueries([GET_CRITERIAS_QUERY_ID]).then();
      }
    }
  );
}

export function useAttachCVToCandidate() {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation((formData: FormData) => attachCvToCandidate(axios, teamId, formData), {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_TARGETS_QUERY_ID]).then();
    }
  });
}
