import { useAuth } from 'contexts/authContext';
import { refreshTokens } from 'services/api/auth';
import { AxiosInstance } from 'axios';

const useRefreshToken = () => {
  const { refreshToken, setToken, saveRefreshToken } = useAuth();

  const refresh = async (axiosInstace: AxiosInstance) => {
    if (!refreshToken) {
      return Promise.reject('No Refresh Token');
    }
    const refreshTokensResponse = await refreshTokens(axiosInstace, refreshToken);
    setToken(refreshTokensResponse.data.accessToken);
    saveRefreshToken(refreshTokensResponse.data.refreshToken);

    return refreshTokensResponse.data.accessToken;
  };
  return refresh;
};

export default useRefreshToken;
