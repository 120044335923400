import {
  industries,
  numberOfPresent,
  timeframes,
  companySizes,
  revenueRanges,
  fundingTypeOptions,
  CategoryDataType,
  cbCategories,
  cbCategoryGroups
} from '@recone/models';

export const initialFilters: CategoryDataType[] = [
  {
    name: 'Profile',
    conjuction: 'AND',
    filters: [
      {
        name: 'First Name',
        path: 'profile.firstName',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Last Name',
        path: 'profile.lastName',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Location',
        path: 'profile.location',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Skills',
        path: 'profile.skills',
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: true,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Headline keywords',
        path: 'profile.headline',
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: true,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'About keywords',
        path: 'profile.summary',
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: true,
        overrideConjunction: false,
        criteria: []
      }
    ]
  },

  {
    name: 'Experience',
    conjuction: 'AND',
    filters: [
      {
        name: 'Present Company',
        path: 'profile.experience',
        startPath: 'start',
        endPath: 'end',
        valuePath: 'companyName',
        filterType: 'multiSelectCreatable',
        timed: true,
        present: true,
        criteria: [],
        conjunctionOverridable: true,
        overrideConjunction: false
      },
      {
        name: 'Past Company',
        path: 'profile.experience',
        startPath: 'start',
        endPath: 'end',
        valuePath: 'companyName',
        filterType: 'multiSelectCreatable',
        timed: true,
        present: false,
        criteria: [],
        conjunctionOverridable: true,
        overrideConjunction: false
      },
      {
        name: 'Present Title',
        path: 'profile.experience',
        startPath: 'start',
        endPath: 'end',
        valuePath: 'title',
        filterType: 'multiSelectCreatable',
        timed: true,
        present: true,
        criteria: []
      },
      {
        name: 'Past Title',
        path: 'profile.experience',
        startPath: 'start',
        endPath: 'end',
        valuePath: 'title',
        filterType: 'multiSelectCreatable',
        timed: true,
        present: false,
        criteria: [],
        conjunctionOverridable: true,
        overrideConjunction: false
      },
      {
        name: 'Present Company Size (Present)',
        path: 'companySize',
        filterType: 'multiSelectFromOptions',
        options: [...companySizes],
        criteria: []
      },
      {
        name: 'Present Company Size (Past)',
        path: 'companySize',
        past: true,
        filterType: 'multiSelectFromOptions',
        options: [...companySizes],
        criteria: []
      },
      {
        name: 'Years in current position',
        path: 'profile.experience',
        startPath: 'start',
        endPath: 'end',
        isTimeframe: true,
        present: true,
        filterType: 'multiSelectFromOptions',
        criteria: [],
        options: [...timeframes]
      },
      {
        name: 'Years of experience',
        path: 'totalExperience',
        filterType: 'multiSelectFromOptions',
        criteria: [],
        options: [...timeframes]
      },
      {
        name: 'Number of present companies',
        path: 'numberOfPresentCompanies',
        filterType: 'multiSelectFromOptions',
        criteria: [],
        options: [...numberOfPresent]
      },
      {
        name: 'Experience keywords (Present)',
        path: 'companies.description',
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: true,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Experience keywords (Past)',
        path: 'companies.description',
        past: true,
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: true,
        overrideConjunction: false,
        criteria: []
      }
    ]
  },
  {
    name: 'Company',
    conjuction: 'AND',
    filters: [
      {
        name: 'Is Company Active',
        path: 'companies.isActive',
        filterType: 'toggleSwitch',
        criteria: []
      },
      {
        name: 'Location',
        path: 'companies.location',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Industry',
        path: 'companies.industry',
        filterType: 'multiSelectFromOptions',
        conjunctionOverridable: false,
        overrideConjunction: false,
        options: [...industries],
        criteria: []
      },
      {
        name: 'Industry (Past)',
        path: 'companies.industry',
        filterType: 'multiSelectFromOptions',
        conjunctionOverridable: false,
        overrideConjunction: false,
        options: [...industries],
        past: true,
        criteria: []
      },
      {
        name: 'Specialities',
        path: 'companies.specialities',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Specialities (Past)',
        path: 'companies.specialities',
        filterType: 'multiSelectCreatable',
        past: true,
        criteria: []
      },
      {
        name: 'Category (CB) (Present)',
        path: 'companies.categories',
        filterType: 'multiSelectFromOptions',
        conjunctionOverridable: true,
        overrideConjunction: true,
        options: [...cbCategories],
        criteria: []
      },
      {
        name: 'Category (CB) (Past)',
        path: 'companies.categories',
        past: true,
        filterType: 'multiSelectFromOptions',
        conjunctionOverridable: true,
        overrideConjunction: true,
        options: [...cbCategories],
        criteria: []
      },
      {
        name: 'Category Groups (CB) (Present)',
        path: 'categoryGroups',
        filterType: 'multiSelectFromOptions',
        conjunctionOverridable: true,
        overrideConjunction: true,
        options: [...cbCategoryGroups],
        criteria: []
      },
      {
        name: 'Category Groups (CB) (Past)',
        path: 'categoryGroups',
        past: true,
        filterType: 'multiSelectFromOptions',
        conjunctionOverridable: true,
        overrideConjunction: true,
        options: [...cbCategoryGroups],
        criteria: []
      },
      {
        name: 'Founded Year (Present)',
        path: 'companies.foundedYear',
        filterType: 'datePicker',
        conjunctionOverridable: false,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Founded Year (Past)',
        path: 'companies.foundedYear',
        past: true,
        filterType: 'datePicker',
        conjunctionOverridable: false,
        overrideConjunction: false,
        criteria: []
      },

      {
        name: 'Company Type',
        path: 'companies.companyType',
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: false,
        overrideConjunction: false,
        // options: [...companyTypes],
        criteria: []
      }
    ]
  },
  {
    name: 'Education',
    conjuction: 'AND',
    filters: [
      {
        name: 'School Name',
        path: 'profile.education.schoolName',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Diploma type',
        path: 'profile.education.degreeName',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Diploma Major',
        path: 'profile.education.fieldOfStudy',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Start Year',
        path: 'profile.education.start',
        filterType: 'datePicker',
        conjunctionOverridable: false,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'End Year',
        path: 'profile.education.end',
        filterType: 'datePicker',
        conjunctionOverridable: false,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Education description keyword',
        path: 'profile.education.description',
        filterType: 'multiSelectCreatable',
        criteria: [],
        conjunctionOverridable: true,
        overrideConjunction: false
      }
    ]
  },
  {
    name: 'Funding',
    conjuction: 'AND',
    filters: [
      {
        name: 'Revenue Range (Present)',
        path: 'revenueRange',
        filterType: 'multiSelectFromOptions',
        options: [...revenueRanges],
        criteria: []
      },
      {
        name: 'Revenue Range (Past)',
        path: 'revenueRange',
        past: true,
        filterType: 'multiSelectFromOptions',
        options: [...revenueRanges],
        criteria: []
      },
      {
        name: 'Last Funding At (Present)',
        path: 'lastFundingAt',
        filterType: 'datePicker',
        conjunctionOverridable: false,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Last Funding At (Past)',
        path: 'lastFundingAt',
        past: true,
        filterType: 'datePicker',
        conjunctionOverridable: false,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Last Funding Type',
        path: 'lastFundingType',
        filterType: 'multiSelectFromOptions',
        options: [...fundingTypeOptions],
        criteria: []
      },
      {
        name: 'Last Funding Type (Past)',
        path: 'lastFundingType',
        past: true,
        filterType: 'multiSelectFromOptions',
        options: [...fundingTypeOptions],
        criteria: []
      },
      {
        name: 'Lead Investor',
        path: 'leadInvestorIdentifiers.name',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Lead Investor (Past)',
        path: 'leadInvestorIdentifiers.name',
        filterType: 'multiSelectCreatable',
        criteria: [],
        past: true
      },
      {
        name: 'Investors',
        path: 'investorIdentifiers.name',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Investors (Past)',
        path: 'investorIdentifiers',
        filterType: 'multiSelectCreatable',
        criteria: [],
        past: true
      },
      {
        name: 'Investment Type',
        path: 'fundingRounds.investmentType',
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: true,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Investment Type (Past)',
        path: 'fundingRounds.investmentType',
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: true,
        overrideConjunction: false,
        criteria: [],
        past: true
      },
      {
        name: 'Number Of Funding Rounds',
        path: 'numFundingRounds',
        filterType: 'number',
        criteria: []
      },/* ,
      {
        name: 'Number Of Funding Rounds (Past)',
        path: 'numFundingRounds',
        filterType: 'number',
        criteria: [],
        past: true
      }, */
      {
        name: 'Total Funding Amount',
        path: 'fundingTotal.amount',
        filterType: 'number',
        criteria: []
      },
/*       {
        name: 'Total Funding Amount (Past)',
        path: 'fundingTotal.amount',
        filterType: 'number',
        criteria: [],
        past: true
      }, */
      {
        name: 'Equity Funding Amount',
        path: 'equityFundingTotal.amount',
        filterType: 'number',
        criteria: []
      },
/*       {
        name: 'Equity Funding Amount (Past)',
        path: 'equityFundingTotal.amount',
        filterType: 'number',
        criteria: [],
        past: true
      }, */
      {
        name: 'Last Funding Amount',
        path: 'lastFundingTotal.amount',
        filterType: 'number',
        criteria: []
      },
/*       {
        name: 'Last Funding Amount (Past)',
        path: 'lastFundingTotal.amount',
        filterType: 'number',
        criteria: [],
        past: true
      }, */
      {
        name: 'Last Equity Funding Amount',
        path: 'lastEquityFundingTotal.amount',
        filterType: 'number',
        criteria: []
      },
/*       {
        name: 'Last Equity Funding Amount (Past)',
        path: 'lastEquityFundingTotal.amount',
        filterType: 'number',
        criteria: [],
        past: true
      }, */
      {
        name: 'Funding Round Amount',
        path: 'fundingRounds.money_raised.amount',
        filterType: 'number',
        criteria: []
      },
/*       {
        name: 'Funding Round Amount (Past)',
        path: 'fundingRounds.money_raised.amount',
        filterType: 'number',
        criteria: [],
        past: true
      }, */
      //
      {
        name: 'Funding Round Date',
        path: 'fundingRounds.announced_on', //64df937319e76df736036a31
        filterType: 'date',
        criteria: []
      },
      {
        name: 'Funding Round Date (Past)',
        path: 'fundingRounds.announced_on',
        filterType: 'date',
        criteria: [],
        past: true
      }
    ]
  }
];
